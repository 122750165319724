import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Seo = ({ lang, meta, title }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    meta={[
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=.9",
      },
      ...meta,
    ]}
    link={[
      {
        rel: "stylesheet",
        href: "https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css",
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css?family=Merriweather:300,700",
      },
    ]}
  />
)

Seo.defaultProps = {
  lang: `en`,
  meta: [],
}

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
